import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Modal, ListGroup } from 'react-bootstrap'
// import ReactAudioPlayer from "react-audio-player";

import { api_url } from '../../../utils/api'
import './styles.scss'
import { axiosInstance } from '../../../actions/authActions'

const ViewBookingDetails = (props) => {
  const token = localStorage.getItem('jwtToken');
  const [commision, setCommision] = useState(0);

  const owner_details = (props.details.ownerId && props.details.ownerId.basicInfo) || {}

  const walker_details = (props.details.serviceProviderId && props.details.serviceProviderId.basicInfo) || {}

  const details = (props.details && props.details) || {}


  const getCommission = () => {
    const fullUrl = `${api_url}/api/admin/convinience/booking/${details._id}?serviceProviderType=${details.serviceProviderType}`;
    axiosInstance.get(fullUrl, { headers: { 'x-access-token': token } })
      .then((res) => {
        setCommision(res.data.data.convinienceFee);
      })
      .catch((err) => console.log(err))
  };

  useEffect(() => {
    if (details._id) {
      getCommission();
    }
  }, [details._id])


  const findServiceType = (type) => {
    switch (type) {
      case 'walking':
        return "Walking";
      case 'grooming':
        return "Grooming";
      case 'sitting':
        return "House Sitting";
      case 'boarding':
        return "Boarding";
      case 'visiting':
        return "Drop-in Visits";
      case 'training':
        return "Training";
      default:
        return "Day";
    }
  }

  const findStatusType = (type) => {
    switch (type) {
      case 'pending':
        return "Pending";
      case 'confirmed':
        return "Confirmed";
      case 'ongoing':
        return "Ongoing";
      case 'completed':
        return "Completed";
      case 'rejected':
        return "Rejected";
      case 'cancelled':
        return "Cancelled";
      default:
        return "";
    }
  }

  return (
    <>
      <Modal
        show={props.showViewModal}
        onHide={() => props.setShowViewModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Booking History Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant='flush'>
            {props.details && (
              <>
                {Object.keys(owner_details).length !== 0 ?
                  <>
                    <h6 className='pl-0'>Owner Details</h6>
                    <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                      Name{' '}
                      <span>
                        {owner_details && owner_details.fullName ? owner_details.fullName : '-'}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                      Email <span>{owner_details && owner_details.email ? owner_details.email : '-'}</span>
                    </ListGroup.Item>
                    <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                      Phone Number{' '}
                      <span>{owner_details && owner_details.phoneNumber ? owner_details.phoneNumber : '-'}</span>
                    </ListGroup.Item>
                  </>
                  : null}
                {Object.keys(walker_details).length !== 0 ?
                  <>
                    <h6 className='pl-0'>Service Provider Details</h6>
                    <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                      Name{' '}
                      <span>
                        {walker_details && walker_details.fullName ? walker_details.fullName : '-'}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                      Email <span>{walker_details && walker_details.email ? walker_details.email : '-'}</span>
                    </ListGroup.Item>
                    <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                      Phone Number{' '}
                      <span>{walker_details && walker_details.phoneNumber ? walker_details.phoneNumber : '-'}</span>
                    </ListGroup.Item>
                  </>
                  : null}

                <h6 className='pl-0'>Booking Details</h6>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Booking ID
                  <span>{details && details._id}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Service Type
                  <span>{details && findServiceType(details.serviceProviderType)}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Date
                  <span>{details && details.startDate}</span>
                </ListGroup.Item>

                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Time Slot
                  <span>{details && details.startTime + (details.endTime ? ` - ${details.endTime}` : "")}</span>
                </ListGroup.Item>
                {details.requestType === 'custom' ?
                  <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                    Services
                    <span style={{ width: '60%', textAlign: 'right' }}>{details && details.requestedService}</span>
                  </ListGroup.Item> : null}
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Amount (with commission)
                  <span>{details && details.price ? (details.price * details.noOfDogs + commision) : "0"}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Amount (without commission)
                  <span>{details && details.price ? (details.price * details.noOfDogs) : '0'}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Status{' '}
                  <span className={details.status}>{findStatusType(details.status)}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Created On{' '}
                  <span>
                    {moment(details.created_at).format('MMM DD, YYYY')}
                  </span>
                </ListGroup.Item>
              </>
            )}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ViewBookingDetails
