

import React, { memo, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getWalker, updateWalker } from '../../../actions/dashboardActions';
import Search from '../../common/Search/Search';
import Table from './Table';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'
import Button from '../../common/Button/Button';

const AppMatrics = () => {
    const [searchValue, setSearchValue] = useState('');
    const [startDate, setStartDate] = useState("");
    const [isFiltered, setIsFiltered] = useState(false);
    const [endDate, setEndDate] = useState("");
    const [list, setList] = useState([
        {
            name: "SP",
            status: "Booked",
            type: "Walking",
        },
        {
            name: "John Doe",
            status: "cancelled",
            type: "House Sitting",
        },
        {
            name: "Julia Doe",
            status: "Booked",
            type: "Grooming",
        }
    ]);
    const handleSearch = (e) => {
        setSearchValue(e);
    }

    const handlePageClick = (e) => {
        console.log(e);
    }

    const handleFilter = (e) => {
        e.preventDefault();
        console.log(startDate, endDate);
        setIsFiltered(true);
    }

    const handleReset = () => {
        setStartDate("");
        setEndDate("");
        setIsFiltered(false);
    }

    return (
        <>

            <div className='manage-doctor-wrapper manage_walkers_page'>
                <h5 className='text-left'>App Metrics</h5>
                <div className='row mx-0'>
                    <div className='col-md-6 pl-0 search_col'>
                        <Search
                            icon='fa fa-search icon'
                            size='15px'
                            placeholder='Search'
                            search={searchValue}
                            handleSearch={handleSearch}
                        />
                    </div>
                </div>
                {/* <div className='col-md-6 pr-0 right_col'>
                        <DatePicker
                            selected={date}
                            onChange={(date) => setDate(date)}
                            className='form-control shadow-none'
                        />
                    </div> */}
                <div className='manage-doctor custom_class'>
                    <form onSubmit={handleFilter}>
                        <div className='row mx-0 mb-4 d-flex align-items-end'>
                            <div className='col-md-2'>
                                <label >From </label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className='form-control shadow-none'
                                />
                            </div>

                            <div className='col-md-2'>
                                <label>To </label>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    className='form-control shadow-none'
                                />
                            </div>

                            <div className='col-md-2 pr-0 right_col'>
                                <Button
                                    type='submit'
                                    value='Submit'
                                    className='btn appointments-filter-submit-btn shadow-none'
                                />
                            </div>

                            <div className='col-md-2'>
                                {isFiltered && (
                                    <span className='reload-btn' onClick={handleReset}>
                                        <i className='fa fa-refresh' />
                                    </span>
                                )}
                            </div>
                        </div>
                    </form>

                    <div className='table-responsive'>
                        <Table list={list} classname='dashboard_table' S />
                    </div>
                </div>

                <div className='row result_row mx-0'>
                    <div className='col-md-6 px-0 left_col'>
                        {!searchValue && (
                            <h6 className='results'>
                                Showing {0} - {0} results of{' '}
                                {0}
                            </h6>
                        )}
                    </div>
                    <div className='col-md-6 px-0 right_col'>
                        {!searchValue && (
                            <Pagination
                                count={0}
                                totalItem={0}
                                pageLimit={10}
                                currentPage={1}
                                handlePageClick={handlePageClick}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    accessRole: state.dashboard.accessRole
})

export default connect(mapStateToProps, { getWalker, updateWalker })(
    memo(AppMatrics)
)
