import React, { Component } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '../../common/Button/Button'
import { addAbout, getCMS } from '../../../actions/dashboardActions'
import './styles.scss'

class UpdateAbout extends Component {
  constructor () {
    super()
    this.state = {
      editor: '',
      text: ''
    }
  }

  componentDidMount () {
    const token = localStorage.getItem('jwtToken')
    this.props.getCMS(token)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.dashboard.cms && nextProps.dashboard.cms.about) {
      this.setState({
        text: nextProps.dashboard.cms.about
      })
    }
  }

  handleInit = (editor) => {
    this.setState({ editor })
  }

  handleChange = (event, editor) => {
    const data = editor.getData()
    this.setState({ text: data })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { text } = this.state
    const token = localStorage.getItem('jwtToken')
    this.props.addAbout(text, token)
  }

  render () {
    return (
      <>
        <div
          className='add-about-wrapper'
        >
          <h5 className='text-left'>About</h5>

          <div className='cms_box'>
            <div className='row mx-0'>
              <div className='col-md-12 px-0 about_form'>
                <form onSubmit={this.handleSubmit}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={this.state.text}
                    onReady={(editor) => this.handleInit(editor)}
                    onChange={(event, editor) =>
                      this.handleChange(event, editor)}
                  />

                  <Button
                    type='submit'
                    className='btn submit-btn shadow-none'
                    value='Update'
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

UpdateAbout.propTypes = {
  dashboard: PropTypes.object.isRequired,
  addAbout: PropTypes.func.isRequired,
  getCMS: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, { addAbout, getCMS })(UpdateAbout)
