import { Multiselect } from "multiselect-react-dropdown"
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import DatePicker from 'react-datepicker'
import { api_url } from '../../../utils/api'
import Button from '../Button/Button'
import './styles.scss'
import moment from 'moment'
import { axiosInstance } from '../../../actions/authActions'

const ExportToExcel = ({ show, handleClose, columnList, apiUrl }) => {
    const token = localStorage.getItem('jwtToken');
    const [selectedColumns, setSelectedValues] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [csvData, setCsvData] = useState([]);
    const [endDate, setEndDate] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(true);
    const dropdownRef = useRef(null);
    const csvLinkRef = useRef();
    const chipLimit = 3;

    const flattenData = (item) => {
        const flattened = {};
        columnList.forEach(col => {
            const keys = col.key.split('.');
            let value = item;
            keys.forEach(key => {
                if (value) {
                    value = value[key];
                }
            });
            if (col.isCheck) {
                flattened[col.key] = value ? col?.if : col?.else;
            } else if (col.date) {
                flattened[col.key] = value ? moment(value).format('MMM DD, YYYY') : "";
            }
            else {
                flattened[col.key] = value;
            }
        });
        return flattened;
    };

    const handleDownload = () => {
        if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
            handleClose();
        }
    };

    const handleSubmitForm = () => {
        if (!selectedColumns?.length || !endDate || !startDate) {
            return;
        }
        const payload = {
            startDate,
            endDate,
            selectedColumns: selectedColumns.map(ele => ele.key)
        }
        axiosInstance.post(api_url + apiUrl, payload, { headers: { 'x-access-token': token } })
            .then((res) => {
                const data = res.data.data;
                const flattenedData = data.map(item => flattenData(item));
                setCsvData(flattenedData);
                handleDownload();
            })
            .catch((err) => console.log(err))
    };


    const onSelect = (selectedList) => {
        setSelectedValues(selectedList);
        setDropdownOpen(true);
    };

    const onRemove = (selectedList) => {
        setSelectedValues(selectedList);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleChipRemove = (index) => {
        setSelectedValues((prevValues) =>
            prevValues.filter((_, idx) => idx !== index)
        );
    };

    const customChips = () => {
        const visibleChips = selectedColumns.slice(0, chipLimit).map((item, index) => (
            <span key={index} className="custom-chip">
                {item.label}
                <i
                    className='fa fa-close ml-2'
                    style={{ color: "white", fontSize: "12px", cursor: "pointer" }}
                    onClick={() => handleChipRemove(index)}
                />
            </span>
        ));
        const remainingCount = selectedColumns.length - chipLimit;

        return (
            <>
                {visibleChips}
                {remainingCount > 0 && (
                    <span className="extra-chip">+{remainingCount} more</span>
                )}
            </>
        );
    };

    return (
        <>
            {(selectedColumns?.length) && (
                <CSVLink
                    data={csvData}
                    headers={selectedColumns}
                    filename={`file.csv`}
                    className="d-none"
                    ref={csvLinkRef}
                />
            )}

            <Modal backdrop="static" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Export data
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-package'>
                    <div className='profile-details'>
                        <form>
                            <div className='d-flex' style={{ gap: '20px' }}>
                                <div>
                                    <label style={{ fontWeight: 600, fontSize: 14 }} htmlFor='selectType' className='mb-1' required>Start Date  <span className='text-danger'>*</span></label>
                                    <div className="datepicker-wrapper">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            className='datePickwerWidth form-control-2 form-control mb-3 d-flex pr-3 paddingLeft datepicker-input'
                                        />
                                        <span className="calendar-icon2">
                                            <i className="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                                <div >
                                    <label style={{ fontWeight: 600, fontSize: 14 }} htmlFor='selectType1' className='mb-1' required>End Date <span className='text-danger'>*</span></label>
                                    <div className="datepicker-wrapper">
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            className='datePickwerWidth form-control-2 form-control mb-3 d-flex pr-3 paddingLeft datepicker-input'
                                        />
                                        <span className="calendar-icon2">
                                            <i className="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <label style={{ fontWeight: 600, fontSize: 14 }} htmlFor='selectType' className='mb-1' required>Select Column Names  <span className='text-danger'>*</span></label>
                            {/* <div className='form-control-2 form-control mb-3 d-flex pr-3 paddingLeft' > */}
                            <div ref={dropdownRef} className="multiselect-wrapper form-control-2 form-control no-border" >
                                <Multiselect
                                    options={columnList}
                                    selectedValues={selectedColumns}
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    displayValue="label"
                                    placeholder="Select Options"
                                    showCheckbox
                                    className="border-none"
                                    hidePlaceholder
                                    closeOnSelect={false}
                                    customCloseIcon={<span style={{ fontSize: "16px" }}>×</span>} // Customize the close icon
                                    style={{
                                        chips: { display: "none" }, // Hide default chips
                                        multiselectContainer: { width: "400px", border: "none" }, // Custom width for dropdown

                                    }}
                                    isObject={true}
                                    onOpen={() => setDropdownOpen(true)}
                                    onClose={() => setDropdownOpen(dropdownOpen)}
                                    customChipRenderer={customChips} // Render the custom chips
                                />
                                <div className="chip-container">{customChips()}</div>
                            </div>
                            {/* </div> */}
                        </form>

                        <Button
                            value='Export'
                            className='btn btn-primary btn-block update-profile-btn shadow-none mt-3'
                            handleClick={() => { handleSubmitForm() }}
                        />
                    </div>
                </Modal.Body >

            </Modal >
        </>
    )
}
export default ExportToExcel;