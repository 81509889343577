import {
  IS_LOADING,
  ADMIN_PROFILE_DATA,
  FAQ,
  ABOUT,
  HELP,
  TERM,
  SINGLE_FAQ,
  ALL_REVIEWS,
  VERIFY_SUCCESS,
  VERIFY_ERROR,
  GET_QUESTION,
  GET_WALKERS,
  GET_WALKER,
  GET_OWNERS,
  GET_OWNER,
  GET_CMS,
  GET_BOOKINGS,
  GET_COMMISSION,
  GET_DELETE_USER,
  GET_USER_DETAIL
} from '../actions/types'

const initialState = {
  loading: false,
  adminProfileData: {},
  appointments: [],
  faq: [],
  about: [],
  help: [],
  term: {},
  reviews: [],
  discount: 0,
  verifyError: '',
  verifySuccess: '',
  singleQuestion: {},
  referredUsers: [],
  allWalkers: [],
  singleWalkerInfo: {},
  walkerBookings: [],
  walkerReviews: [],
  allOwners: [],
  singleOwnerInfo: {},
  ownerBookings: {},
  ownerReviews: {},
  cms: [],
  bookings: [],
  singleCommission: {},
  accessRole: '2',
  allDeletedUser: [],
  userInfo: {},
  userBookings: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case ADMIN_PROFILE_DATA:
      return {
        ...state,
        adminProfileData: action.payload,
        accessRole: action.payload.role === 'superadmin' ? '0' : action.payload.role === 'admin' ? '1' : '2'
      }

    case GET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload
      }
    case FAQ:
      return {
        ...state,
        faq: action.payload
      }
    case SINGLE_FAQ:
      return {
        ...state,
        singleFAQ: action.payload
      }
    case ABOUT:
      return {
        ...state,
        about: action.payload
      }
    case HELP:
      return {
        ...state,
        help: action.payload
      }
    case TERM:
      return {
        ...state,
        term: action.payload
      }

    case ALL_REVIEWS:
      return {
        ...state,
        reviews: action.payload
      }

    case VERIFY_SUCCESS:
      return {
        ...state,
        verifySuccess: action.payload
      }
    case VERIFY_ERROR:
      return {
        ...state,
        verifyError: action.payload
      }

    case GET_QUESTION:
      return {
        ...state,
        singleQuestion: action.payload
      }
    case GET_WALKERS:
      return {
        ...state,
        allWalkers: [...action.payload]
      }
    case GET_WALKER:
      return {
        ...state,
        singleWalkerInfo: action.walkerInfo,
        walkerBookings: action.walkerBookings,
        walkerReviews: action.walkerReviews
      }
    case GET_OWNERS:
      return {
        ...state,
        allOwners: [...action.payload]
      }
    case GET_DELETE_USER:
      return {
        ...state,
        allDeletedUser: [...action.payload]
      }
    case GET_CMS:
      return {
        ...state,
        cms: action.payload
      }
    case GET_OWNER:
      return {
        ...state,
        singleOwnerInfo: action.ownerInfo,
        ownerBookings: action.ownerBookings,
        ownerReviews: action.ownerReviews
      }

    case GET_USER_DETAIL:
      return {
        ...state,
        userInfo: action.userInfo,
        userBookings: action.userBookings,
      }
    case GET_COMMISSION:
      return {
        ...state,
        singleCommission: action.payload
      }
    default:
      return state
  }
}
