import React, { memo, useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import BasicDetails from './BasicDetails'

import Form from './Form'
import BookingHistory from './BookingHistory'
import { validateEditWalker } from '../../../validations/add-walker'
import { getWalker, updateWalker } from '../../../actions/dashboardActions'
import { api_url } from '../../../utils/api'
import Pagination from '../../common/Pagination/Pagination'
import { axiosInstance } from '../../../actions/authActions'

const WalkerDetails = (props) => {
  const { id } = useParams()
  const [fullName, setFullName] = useState('')
  const userData = props.location.state ? props.location.state : JSON.parse(localStorage.getItem("spuserData"));
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [insuranceProof, setInsuranceProof] = useState('')
  const [photoId, setPhotoId] = useState('');
  const [tableData, setTableData] = useState({
    data: [],
    totalBookings: 0,
    totalPages: 0
  })
  const [payload, setPayload] = useState({
    userType: "serviceProvider",
    page: 1,
    limit: 10
  });
  const token = localStorage.getItem('jwtToken')
  const startResult = (payload.page - 1) * payload.limit + 1;
  const endResult = Math.min(payload.page * payload.limit, tableData.totalBookings);

  useEffect(() => {
    if (props.location?.state) {
      localStorage.setItem("spuserData", JSON.stringify(props.location?.state))
    }
  }, [props.location]);

  useEffect(() => {
    if (userData.el.basicInfo) {
      const data = userData

      const basicInfo = data && data.el.basicInfo
      const walkerInsuranceProof =
        basicInfo && basicInfo.insuranceProof
          ? basicInfo.insuranceProof
          : api_url + '/default.png'
      setInsuranceProof(walkerInsuranceProof)

      const walkerPhotoId =
        basicInfo && basicInfo.photoId
          ? basicInfo.photoId
          : api_url + '/default.png'
      setPhotoId(walkerPhotoId)
      const {
        image,
        fullName,
        email,
        phoneNumber
      } = userData.el.basicInfo
      setFullName(fullName || '')
      setEmail(email || '')
      setPhoneNumber(phoneNumber || '')
    }
  }, [props])

  const handleDetailsUpdate = (e) => {
    e.preventDefault()

    const result = validateEditWalker(fullName, email, phoneNumber)
    if (result) return toast.error(result)

    const walker = {
      id,
      fullName,
      email,
      phoneNumber
    }

    props.updateWalker(walker, token)
    props.getWalker(id, token)
  }

  // const imageModalHandler = (image) => {
  //   console.log("imagess--", image);
  //   setImageModal(insuranceProof);
  // };

  const getData = () => {
    const params = new URLSearchParams(payload).toString();
    const fullUrl = `${api_url}/api/admin/get/booking/${id}?${params}`;
    // const fullUrl = `${api_url}/api/admin/get/booking/66aba95753cf3def2726fe9c?${params}`;
    axiosInstance.get(fullUrl, payload, { headers: { 'x-access-token': token } })
      .then((res) => {
        setTableData(res.data);
      })
      .catch((err) => console.log(err))
  };

  const handlePageClick = (pageNumber) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      page: pageNumber,
    }));
  }

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id, payload.page]);

  const toDataURL = (url) => {
    return fetch(url).then((response) => {
      return response.blob()
    }).then(blob => {
      return URL.createObjectURL(blob)
    })
  }
  const download = async (file, filename) => {
    const link = document.createElement('a')
    if (file.includes('api.gowalkies.app')) {
      link.href = await toDataURL(file)
    } else {
      link.href = file
    }

    link.download = filename + '.jpg'
    document.body.appendChild(link)

    link.click()
    document.body.removeChild(link)
  }


  return (
    <>
      {/* {<ImageModal imgSrc={about} />} */}
      <div className='doctor-details'>
        <h3 className='text-left'>Service Provider Details</h3>
        <div className='row mx-0'>
          <div className='col-md-4 pl-0'>
            <div className='basic-details'>
              <BasicDetails data={userData?.el} />
            </div>
          </div>
          <div className='col-md-8 pr-0'>
            <div className='personal-details'>
              <h5 className='text-left'>Personal Details</h5>
              <Form
                fullName={fullName}
                setFullName={setFullName}
                email={email}
                setEmail={setEmail}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                handleDetailsUpdate={handleDetailsUpdate}
                accessRole={props.accessRole}
              />
            </div>
          </div>
        </div>

        {/* <div className='row mx-0 proof_row'>
          <div className='col-sm-6 pl-0'>
            <h4 className='proof_heading'>Insurance Proof</h4>
            <div className='basic-details'>

                   <div
              style={{cursor:'pointer'}}
                onClick={() => download(insuranceProof, 'InsuranceProof')}
              >
                <img
                  src={insuranceProof}
                  alt='profile-img'
                  className='proof_img'
                />
                <i className='fa fa-download' />
              </div>
            </div>
          </div>
          <div className='col-sm-6 pr-0'>
            <h4 className='proof_heading'>Photo ID</h4>
            <div className='basic-details'>
                         <div
              style={{cursor:'pointer'}}
                onClick={() => download(photoId, 'PhotoId')}
              >

                <img src={photoId} alt='profile-img' className='proof_img' />
                <i className='fa fa-download' />
              </div>

            </div>
          </div>
        </div> */}

        <div className='row mx-0'>
          <div className='col-md-12 px-0'>
            <div className='appointment-history'>
              <h5 className='text-left'>Booking History</h5>
              <div className='table-responsive'>
                {' '}
                <BookingHistory
                  data={tableData.data}
                  walkerInfo={props.dashboard.walkerInfo}
                  currentPage={payload.page}
                />
              </div>
              {tableData?.totalBookings ? <div className='row result_row mx-0'>
                <div className='col-md-6 px-0 left_col'>
                  <h6 className='results'>
                    Showing {startResult} - {endResult} results of{' '}
                    {tableData?.totalBookings}
                  </h6>
                </div>
                <div className='col-md-6 px-0 right_col'>
                  <Pagination
                    count={tableData?.totalPages ? tableData?.totalPages : 0}
                    totalItem={tableData?.totalBookings ? tableData?.totalBookings : 0}
                    pageLimit={10}
                    currentPage={payload.page}
                    handlePageClick={handlePageClick}
                  />
                </div>
              </div> : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

WalkerDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getWalker: PropTypes.func.isRequired,
  updateWalker: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  accessRole: state.dashboard.accessRole
})

export default connect(mapStateToProps, { getWalker, updateWalker })(
  memo(WalkerDetails)
)
