import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

const Table = (props) => {

  const [status, setStatus] = React.useState('');
  console.log(props.bookings, 'props.bookings');
  return (
    <>
      <table className={`table booking-table`}>
        <thead>
          <tr>
            <th>#</th>
            <th>Booking ID</th>
            <th>Owner</th>
            <th>Walker</th>
            <th title='Rate for owner'>Rate for owner</th>
            <th title='Rate for walker'>Rate for walker</th>
            <th title='Registration date'>Registration date</th>
            <th title='Booking date'>Booking date </th>
            <th title='Review for walker'>Review for walker</th>
            <th title='Review for owner'>Review for owner</th>
            {/* <th>Status</th> */}
            <th>
              <select
                name='selectType'
                className='shadow-none border-0 w-100 select-status'
                value={status}
                placeholder='Select Service'
                onChange={(e) => { setStatus(e.target.value); props.handleStatusFilter(e.target.value) }}
                style={{
                  outline: 'none',
                  boxShadow: 'none'
                }}
              >
                <option value=''>Status</option>
                <option value='sent'>Sent</option>
                <option value='completed'>Completed</option>
                <option value='rejected'>Rejected</option>
                <option value='accepted'>Accepted</option>
                <option value='cancelled'>Cancelled</option>
                <option value='confirmed'>Confirmed</option>
                <option value='pending'>Pending</option>
              </select>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.bookings &&
            props.bookings.length > 0 &&
            props.bookings.map((a, index) =>
            (
              <tr key={a._id}>
                <td>{((props.currentPage - 1) * 10) + (index + 1)}</td>
                <td>{'Gowalk' + a._id.substring(a._id.length - 4, a._id.length)}</td>
                <td>{a.ownerId ? a.ownerId.basicInfo.fullName : '-'} </td>
                <td>{a.walkerId ? a.walkerId.basicInfo.fullName : '-'} </td>
                <td>{a.ratingByWalker ? a.ratingByWalker.rating : '-'}</td>
                <td>{a.ratingByOwner ? a.ratingByOwner.rating : '-'}</td>
                <td>{a.created_at ? moment(a.created_at).format('MMM DD, YYYY') : '-'}</td>

                <td>{a.date || '-'}</td>

                <td
                  onClick={() =>
                    props.handleReview(
                      a.ratingByWalker && a.ratingByWalker.review || '-',
                      'Review For Walker', a._id, 'owner', a?.ratingByWalker?.isRatingApproved || ''
                    )}
                >
                  {
                    a?.ratingByWalker?.isRatingApproved || a?.ratingByWalker?.isRatingApproved === undefined ?
                      <i className='fa fa-eye ' style={{ cursor: 'pointer' }} />
                      :
                      <i class="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer' }} />
                  }

                </td>
                <td
                  onClick={() =>
                    props.handleReview(
                      a.ratingByOwner && a.ratingByOwner.review || '-',
                      'Review For Owner', a._id, 'walker', a?.ratingByOwner?.isRatingApproved || ''
                    )}
                >
                  {
                    a?.ratingByOwner?.isRatingApproved || a?.ratingByOwner?.isRatingApproved === undefined ?
                      <i className='fa fa-eye ' style={{ cursor: 'pointer' }} />
                      :
                      <i class="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer' }} />
                  }
                </td>
                <td>
                  <span className={a.status}>{a.status?.charAt(0).toUpperCase() + a.status?.slice(1)}</span>
                </td>
                <td>
                  <Link to='#' className="c" onClick={() => props.showBookingDetails(a)}>
                    <i className='fa fa-eye' style={{ cursor: 'pointer' }} />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

export default Table
