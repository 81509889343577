import { Modal } from 'react-bootstrap';
import React from 'react';

const ViewDocument = (props) => {
    const { url, show, handleClose } = props;

    return (
        <>
            <Modal backdrop="static" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>View Document</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-package'>
                    <img src={url} alt="Document" style={{ width: '100%', height: 'auto' }} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ViewDocument;
