

import React, { memo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getWalker, updateWalker } from '../../../actions/dashboardActions';
import './styles.scss';
import DatePicker from 'react-datepicker'
import Button from '../../common/Button/Button';
import { CSVLink } from 'react-csv';
import { api_url } from '../../../utils/api';
import moment from 'moment';
import { axiosInstance } from '../../../actions/authActions';

const MicroInsurance = () => {
    const token = localStorage.getItem('jwtToken')
    const [startDate, setFromDate] = useState(null);
    const [endDate, setToDate] = useState(null);
    const [service, setServiceType] = useState(null);
    const csvLinkRef = useRef();
    const [csvData, setCsvData] = useState([]);
    const [columns, setColumn] = useState([
        { label: "Date and Time of Booking", key: "bookingDateTime", date: true },
        { label: "Service Start Time", key: "serviceStartTime" },
        { label: "Service End Time", key: "serviceEndTime", isTime: true },

        { label: "Owner Name", key: "ownerDetails.name" },
        { label: "Owner Address", key: "ownerDetails.address" },
        { label: "Owner DOB", key: "ownerDetails.dateOfBirth", date: true },
        { label: "Owner Gender", key: "ownerDetails.gender" },
        { label: "Owner verification document", key: "ownerDetails.verificationDetails.document" },
        { label: "Owner verification document type", key: "ownerDetails.verificationDetails.documentType" },
        { label: "Owner verification document status", key: "ownerDetails.verificationDetails.profileStatus" },

        { label: "Service provider Name", key: "serviceProviderDetails.name" },
        { label: "Service provider Address", key: "serviceProviderDetails.address" },
        { label: "Service provider DOB", key: "serviceProviderDetails.dateOfBirth", date: true },
        { label: "Service provider Gender", key: "serviceProviderDetails.gender" },
        { label: "Service Provider verification document", key: "serviceProviderDetails.verificationDetails.document" },
        { label: "Service Provider verification document type", key: "serviceProviderDetails.verificationDetails.documentType" },
        { label: "Service Provider verification document status", key: "serviceProviderDetails.verificationDetails.profileStatus" },

        { label: "Service start location", key: "locationDetails.startLocation", isLocation: true },
        { label: "Service mid location", key: "locationDetails.midLocation", isLocation: true },
        { label: "Service end location", key: "locationDetails.endLocation", isLocation: true },

    ])

    // const handleFilter = (e) => {
    //     e.preventDefault()
    //     if (!startDate || !endDate || !service) {
    //         return;
    //     }
    //     const payload = {
    //         startDate,
    //         endDate,
    //         service
    //     }
    //     axios.post(api_url + `/api/admin/get/selected-bookings`, payload, { headers: { 'x-access-token': token } })
    //         .then((res) => {
    //             const data = res.data.data;
    //             const flattenedData = data.map(item => flattenData(item));
    //             setCsvData(flattenedData);
    //             handleDownload();
    //         })
    //         .catch((err) => console.log(err))
    // }

    const handleFilter = (e) => {
        e.preventDefault();
        if (!startDate || !endDate || !service) {
            return;
        }
        const payload = {
            startDate,
            endDate,
            service
        };
        axiosInstance.post(api_url + `/api/admin/get/selected-bookings`, payload, { headers: { 'x-access-token': token } })
            .then((res) => {
                const data = res.data.data;

                // Determine the maximum number of dogs across all records
                const maxDogs = data.reduce((max, item) => Math.max(max, item.dogs.length), 0);

                // Generate headers dynamically based on the maximum number of dogs
                const dynamicColumns = [
                    { label: "Date of Booking", key: "bookingDateTime", dateAndTime: true },
                    { label: "Service Start Date & Time", key: "serviceStartTime" },
                    { label: "Service End Date & Time", key: "serviceEndTime" },
                    { label: "Owner Name", key: "ownerDetails.name" },
                    { label: "Owner Address", key: "ownerDetails.address" },
                    { label: "Owner DOB", key: "ownerDetails.dateOfBirth" },
                    { label: "Owner Gender", key: "ownerDetails.gender" },
                    { label: "Owner verification document", key: "ownerDetails.verificationDetails.document" },
                    { label: "Owner verification document type", key: "ownerDetails.verificationDetails.documentType" },
                    { label: "Owner verification document status", key: "ownerDetails.verificationDetails.profileStatus" },
                    ...Array.from({ length: maxDogs }, (_, index) => ([
                        { label: `Dog${index + 1} Name`, key: `dog${index + 1}Name` },
                        { label: `Dog${index + 1} Breed`, key: `dog${index + 1}Breed` },
                        { label: `Dog${index + 1} Age`, key: `dog${index + 1}Age` },
                        { label: `Dog${index + 1} Health Issues`, key: `dog${index + 1}HealthIssues` }
                    ])).flat(),
                    { label: "Service Provider Name", key: "serviceProviderDetails.name" },
                    { label: "Service Provider Address", key: "serviceProviderDetails.address" },
                    { label: "Service Provider DOB", key: "serviceProviderDetails.dateOfBirth" },
                    { label: "Service Provider Gender", key: "serviceProviderDetails.gender" },
                    { label: "Service Provider verification document", key: "serviceProviderDetails.verificationDetails.document" },
                    { label: "Service Provider verification document type", key: "serviceProviderDetails.verificationDetails.documentType" },
                    { label: "Service Provider verification document status", key: "serviceProviderDetails.verificationDetails.profileStatus" },
                    { label: "Service start location", key: "locationDetails.startLocation", isLocation: true },
                    { label: "Service mid location", key: "locationDetails.midLocation", isLocation: true },
                    { label: "Service end location", key: "locationDetails.endLocation", isLocation: true },
                ];

                // Flatten data for CSV export
                const flattenedData = data.map(item => flattenData(item, maxDogs));
                setColumn(dynamicColumns);
                setCsvData(flattenedData);
                handleDownload();
            })
            .catch((err) => console.log(err));
    };

    // Flatten data including dynamic dog details based on maxDogs
    const flattenData = (item, maxDogs) => {
        const flattened = {};

        columns.forEach(col => {
            const keys = col.key.split('.');
            let value = item;
            keys.forEach(key => {
                if (value) {
                    value = value[key];
                }
            });
            if (col.isCheck) {
                flattened[col.key] = value ? col?.if : col?.else;
            } else if (col.date) {
                flattened[col.key] = value ? moment(value).format('MMM DD, YYYY') : "";
            } else if (col.isTime) {
                flattened[col.key] = value ? moment(value).format('hh:mm') : "";
            } else if (col.dateAndTime) {
                flattened[col.key] = value ? moment(value).format('MMM DD, YYYY hh:mm A') : "";
            } else if (col.isLocation) {
                flattened[col.key] = value?.length ? value.join(', ') : "";
            } else {
                flattened[col.key] = value;
            }
        });

        // Handle dynamic dog details based on maxDogs
        for (let i = 0; i < maxDogs; i++) {
            const dog = item.dogs[i] || {};
            flattened[`dog${i + 1}Name`] = dog.dogName || "";
            flattened[`dog${i + 1}Breed`] = dog.dogBreed || "";
            flattened[`dog${i + 1}Age`] = dog.dogAge || "";
            flattened[`dog${i + 1}HealthIssues`] = dog.dogHealthIssues || "";
        }

        return flattened;
    };



    const handleFromDateChange = (date) => {
        setFromDate(date);
    }

    const handleToDateChange = (date) => {
        setToDate(date);
    }

    // const flattenData = (item) => {
    //     const flattened = {};
    //     columns.forEach(col => {
    //         const keys = col.key.split('.');
    //         let value = item;
    //         keys.forEach(key => {
    //             if (value) {
    //                 value = value[key];
    //             }
    //         });
    //         if (col.isCheck) {
    //             flattened[col.key] = value ? col?.if : col?.else;
    //         } else if (col.date) {
    //             flattened[col.key] = value ? moment(value).format('MMM DD, YYYY') : "";
    //         }
    //         else {
    //             flattened[col.key] = value;
    //         }
    //     });
    //     return flattened;
    // };


    const handleDownload = () => {
        if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
        }
    };

    return (
        <>
            <div className='appointments-wrapper'>
                <h5 className='text-left'>Micro Insurance</h5>
                <div className='appointments custom_class'>
                    <form onSubmit={handleFilter}>
                        <div className='row mx-0 mb-4 d-flex align-items-end'>
                            <div className='col-md-2'>
                                <label className='date-picker-label'>From </label>
                                <div className="datepicker-wrapper">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => handleFromDateChange(date)}
                                        className="form-control shadow-none datepicker-input"
                                    />
                                    <span className="calendar-icon">
                                        <i className="fa fa-calendar"></i>
                                    </span>
                                </div>
                            </div>

                            <div className='col-md-2'>
                                <label className='date-picker-label'>To </label>
                                <div className="datepicker-wrapper">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => handleToDateChange(date)}
                                        minDate={startDate}
                                        className="form-control shadow-none datepicker-input pl-30px"
                                    />
                                    <span className="calendar-icon">
                                        <i className="fa fa-calendar"></i>
                                    </span>
                                </div>
                            </div>

                            <div className='col-md-2'>
                                <label className='date-picker-label'>Service type </label>
                                <select
                                    name='selectType'
                                    className='form-control shadow-none'
                                    value={service}
                                    placeholder='Select Service'
                                    onChange={(e) => { setServiceType(e.target.value) }}
                                    style={{
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                >
                                    <option value=''>Select</option>
                                    <option value='walking'>Walking</option>
                                    <option value='training'>Training</option>
                                    <option value='boarding'>Boarding</option>
                                    <option value='sitting'>House Sitting</option>
                                    <option value='visiting'>Drop in visits</option>
                                    <option value='grooming'>Grooming</option>
                                </select>
                            </div>

                            <div className='col-md-2 pr-0 right_col'>
                                <Button
                                    type='submit'
                                    value='Export'
                                    className='btn appointments-filter-submit-btn shadow-none'
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>


            <CSVLink
                data={csvData}
                headers={columns}
                filename={`file.csv`}
                className="d-none"
                ref={csvLinkRef}
            />

        </>
    )
}

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    accessRole: state.dashboard.accessRole
})

export default connect(mapStateToProps, { getWalker, updateWalker })(
    memo(MicroInsurance)
)
