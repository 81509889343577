import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ViewBookingDetails from '../../common/Modal/ViewBookingDetails'

const BookingHistory = (props) => {
  const { data, accountType } = props
  const [details, setDetails] = useState({})
  const [showViewModal, setShowViewModal] = useState(false)

  const handleClick = (details) => {
    setShowViewModal(true)
    setDetails(details)
  }

  return (
    <>
      <table className='table'>
        <thead>
          <tr>
            <th>#</th>
            {accountType !== 'owner' ?
              <th>Owner</th> : null}
            {accountType !== 'walker' ?
            <th>Service Provider</th>:null}
            <th>Fee</th>
            <th>Status</th>
            <th>Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((el, index) => (

              <tr key={el._id}>
                <td>{index + 1}</td>
                {accountType !== 'owner' ?
                  < td>
                    {el && el.ownerId && el.ownerId.basicInfo
                      ? el.ownerId.basicInfo.fullName
                      : ''}
                  </td>
                  : null}
                {accountType !== 'walker' ?
                  <td>
                    {el && el.walkerId && el.walkerId.basicInfo
                      ? el.walkerId.basicInfo.fullName
                      : '-'}
                  </td>
                  : null}
                <td>{el.paymentAmount ? el.paymentAmount : '-'}</td>
                <td>
                  <span className={el && el.status}>{el && el.status}</span>
                </td>
                <td>
                  {el && el.date}
                </td>
                <td>
                  {el && el.startTime}
                </td>
                <td>
                  {el && el.endTime}
                </td>
                <td>
                  <Link to='#' onClick={() => handleClick(el)}>
                    <i className='fa fa-eye' />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <ViewBookingDetails
        showViewModal={showViewModal}
        setShowViewModal={setShowViewModal}
        details={details}
      />
    </>
  )
}

export default BookingHistory
