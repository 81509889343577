import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { api_url } from '../../../utils/api'
import Button from '../Button/Button'
import FormInput from '../Form-Input/FormInput'
import './styles.scss'
import { toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import FormInputSuffix from '../Form-Input/FormInputSuffix'
import { axiosInstance } from '../../../actions/authActions'
 
const AddPackageModal = ({ show, handleClose, getTableData, details }) => {
    const bundleSize = 3;
    const token = localStorage.getItem('jwtToken');
    const [isValidate, setIsValidate] = useState(false);
    const [packageData, setPackageData] = useState({
        title: '',
        description: [],
        text: '',
        serviceProviderType: 'walking',
        bundle: [
            {
                quantity: 0,
                // startDate: null,
                // endDate: null,
                expiresInDays: ""
            }
        ],
        duration: "30",
        durationUnit: "min"
    });
 
    const handleSubmitForm = () => {
        if (packageData.title === '' || packageData.description.length < 2) {
            return;
        }
 
        const payload = {
            ...packageData,
            bundle: packageData.bundle?.filter((item) => item.quantity > 0),
            packageType: "inbuilt"
        }
        axiosInstance.post(api_url + `/api/admin/package/create`, payload, { headers: { 'x-access-token': token } })
            .then((res) => {
                if (res.data.success) {
                    toast.success(res.data.message);
                    getTableData();
                    handleClose();
                } else {
                    toast.error(res.data.message)
                }
            })
            .catch((err) => console.log(err))
    };
 
    const updateData = () => {
        if (packageData.title === '' || packageData.description.length === 0) {
            return;
        }
 
        const payload = {
            ...packageData,
            bundle: packageData.bundle?.filter((item) => item.quantity > 0),
            packageType: "inbuilt"
        }
        axiosInstance.put(api_url + `/api/admin/package/update/${packageData?._id}`, payload, { headers: { 'x-access-token': token } })
            .then((res) => {
                if (res.data.success) {
                    toast.success(res.data.message);
                    getTableData();
                    handleClose();
                } else {
                    toast.error(res.data.message)
                }
            })
            .catch((err) => console.log(err))
    };
 
    useEffect(() => {
        if (details) {
            setPackageData({ ...details, serviceProviderType: details.serviceProviderType });
        }
    }, [details])
 
    return (
        <>
            <Modal backdrop="static" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {packageData?._id ? "Update Package" : "Add Package"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-package'>
                    <div className='profile-details'>
                        <form>
                            <label style={{ fontWeight: 600, fontSize: 14 }} htmlFor='selectType' className='mb-1' required>Service Type  <span className='text-danger'>*</span></label>
                            <div className='form-control-2 form-control mb-3 d-flex pr-3 paddingLeft'>
                                <select
                                    name='selectType'
                                    className='shadow-none border-0 w-100'
                                    value={packageData.serviceProviderType}
                                    placeholder='Select Service'
                                    onChange={(e) => setPackageData({ ...packageData, serviceProviderType: e.target.value, duration: "" })}
                                    style={{
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                >
                                    <option value='walking'>Walking</option>
                                    <option value='boarding'>Boarding</option>
                                    <option value='sitting'>House Sitting</option>
                                    <option value='visiting'>Drop in visits</option>
                                    <option value='grooming'>Grooming</option>
                                    {/* <option value='training'>Training</option> */}
                                </select>
                            </div>
 
                            <label style={{ fontWeight: 600, fontSize: 14 }} htmlFor='selectType1' className='mb-1' required>Service Duration <span className='text-danger'>*</span></label>
                            {packageData.serviceProviderType === "sitting" ?
 
                                <div className='form-control-2 form-control mb-3 d-flex pr-3 paddingLeft'>
                                    <select
                                        name='selectType1'
                                        className='shadow-none border-0 w-100'
                                        value={packageData.duration}
                                        placeholder='Select Service'
                                        onChange={(e) => setPackageData({ ...packageData, duration: e.target.value, durationUnit: "hr" })}
                                        style={{
                                            outline: 'none',
                                            boxShadow: 'none'
                                        }}
                                    >
                                        <option value='' disabled>Select</option>
                                        <option value='1'>1 Hr</option>
                                        <option value='2'>2 Hr</option>
                                        <option value='3'>3 Hr</option>
                                        <option value='4'>4 Hr</option>
                                        <option value='5'>5 Hr</option>
                                        <option value='6'>6 Hr</option>
                                        <option value='7'>7 Hr</option>
                                        <option value='8'>8 Hr</option>
                                    </select>
                                </div>
                                :
                                (packageData.serviceProviderType !== "training" && packageData.serviceProviderType !== "boarding") ?
                                    <>
                                        <div className='form-control-2 form-control  d-flex pr-3 paddingLeft'>
                                            <select
                                                name='selectType1'
                                                className='shadow-none border-0 w-100'
                                                value={packageData.duration}
                                                placeholder='Select Service'
                                                onChange={(e) => setPackageData({ ...packageData, duration: e.target.value, durationUnit: "min" })}
                                                style={{
                                                    outline: 'none',
                                                    boxShadow: 'none'
                                                }}
                                            >
                                                <option value='' disabled>Select</option>
                                                <option value='30'>30 Min</option>
                                                <option value='60'>60 Min</option>
                                                <option value='90'>90 Min</option>
                                                <option value='120'>120 Min</option>
                                            </select>
                                        </div>
 
                                        <label className='text-danger mb-3'>{isValidate && packageData.duration === '' ? 'Duration is required' : ""}</label>
                                    </> :
                                    <>
                                        <FormInput
                                            size='15px'
                                            type='number'
                                            name='duration'
                                            value={packageData.duration}
                                            placeholder='Enter duration in days'
                                            handleChange={(e) => setPackageData({ ...packageData, duration: e.target.value, durationUnit: "days" })}
                                            classname='form-control-2 p-0'
                                        />
                                        <label className='text-danger mb-3'>{isValidate && packageData.duration === '' ? 'Duration is required' : parseInt(packageData.duration) < 1 ? "Duration is required" : parseInt(packageData.duration) > 50 ? "Duration should not be more than 50 days" : ""}</label>
                                    </>
                            }
 
                            <div className='form-group mb-3'>
 
                                <label style={{ fontWeight: 600, fontSize: 14 }} htmlFor='title' className='mb-1'>Title <span className='text-danger'>*</span></label>
                                <FormInput
                                    size='15px'
                                    type='text'
                                    name='title'
                                    value={packageData.title}
                                    placeholder='Enter title here'
                                    handleChange={(e) => setPackageData({ ...packageData, title: e.target.value })}
                                    classname='form-control-2 p-0'
                                />
                                <label className='text-danger'>{isValidate && packageData.title === '' ? 'Title is required' : ''}</label>
                            </div>
                            <div className='form-group mb-3'>
                                <label style={{ fontWeight: 600, fontSize: 14 }} htmlFor='description'>Package Included Items <span className='text-danger'>*</span></label>
                                <div className='d-flex align-items-center'>
                                    <FormInput
                                        size='15px'
                                        type='text'
                                        name='text'
                                        value={packageData.text}
                                        placeholder='Add item details'
                                        handleChange={(e) => setPackageData({ ...packageData, text: e.target.value })}
                                        classname='form-control-2 p-0 mb-0 flex-grow-1'
                                    />
                                    {packageData.description.length === 5 ? "" : <div onClick={() => {
                                        if (packageData.text) {
                                            setPackageData({ ...packageData, description: [...packageData.description, packageData.text], text: '' })
                                        }
                                    }} style={{ cursor: 'pointer' }} title='Add more'>
                                        <i class="fa fa-plus"></i>
                                    </div>}
                                </div>
                                <label className='text-danger'>{isValidate && packageData.description.length === 0 ? 'Item details are required' : (packageData.description.length > 0 && packageData.description.length < 2) ? "Minimum two items details required" : ''}</label>
 
                            </div>
 
                            {packageData.description.map((item, index) => (
                                <div className='d-flex justify-content-between ml-0 mb-2'>
                                    <div>{index + 1}. {item}</div>
 
                                    <div title='Remove' style={{ cursor: 'pointer' }} onClick={() => setPackageData({ ...packageData, description: packageData.description.filter((i, ind) => ind !== index) })}>
                                        <i class="fa fa-minus"></i>
                                    </div>
                                </div>
                            ))}
 
                            {packageData.serviceProviderType !== "boarding" && <h5 style={{ fontWeight: 600, fontSize: 14 }}>Bundles</h5>}
                            {packageData.serviceProviderType !== "boarding" &&
                                packageData.bundle.map((bundle, index) => (
                                    <>{bundle.isDeleted ? "" : <>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label style={{ fontWeight: 600, fontSize: 14 }}>Bundle {index + 1}</label>
                                            {/* <div title='Remove'  */}
                                            {/* // className='col-md-3 pr-0 right_col justify-content-between align-items-center' */}
                                            {/* > */}
                                            {/* <Button
                                        type={'button'}
                                        value='Remove'
                                        className='btn btn-primary btn-block remove-profile-btn shadow-none'
                                        handleClick={() => setPackageData({ ...packageData, bundle: packageData.bundle.filter((item, i) => i !== index) })}
                                    /> */}
                                            {packageData.bundle.length < 2 ? "" : <i
                                                style={{ cursor: 'pointer' }}
                                                title='Remove'
                                                className='fa fa-trash'
                                                // onClick={() => setPackageData({ ...packageData, bundle: packageData.bundle.filter((item, i) => i !== index) })}
                                                onClick={() => setPackageData({
                                                    ...packageData,
                                                    bundle: packageData.bundle.map((item, i) =>
                                                        i === index ? { ...item, isDeleted: true } : item
                                                    )
                                                })}
                                            />}
                                            {/* </div> */}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='form-group'>
                                                <label style={{ fontWeight: 600, fontSize: 14 }} htmlFor='quantity'>Quantity</label>
                                                <FormInput
                                                    size='15px'
                                                    type='number'
                                                    name='quantity'
                                                    value={bundle.quantity}
                                                    disabled={bundle.isNonUpdate}
                                                    placeholder='Type bundle quantity here'
                                                    classname='form-control-2 p-0 mb-0 flex-grow-1'
                                                    handleChange={(e) => setPackageData({ ...packageData, bundle: packageData.bundle.map((item, i) => i === index ? { ...item, quantity: e.target.value } : item) })}
                                                />
                                                <label style={{ visibility: "hidden" }} className='text-danger'>{isValidate && bundle.quantity && bundle.expiresInDays === '' ? 'Required' : parseInt(bundle.expiresInDays) < 5 ? "Days should be more than 5 days" : parseInt(bundle.expiresInDays) > 360 ? "Days should not be more than 360 days" : ""}</label>
                                            </div>
                                            <div className='form-group'>
 
                                                <label style={{ fontWeight: 600, fontSize: 14 }} htmlFor='selectType1' required>Duration <span className='text-danger'></span> <i style={{ fontSize: "15px" }} title="This bundle's validity starts at the time of purchase and lasts for the number of days mentioned here." class="fa fa-info-circle"></i></label>
 
                                                <FormInputSuffix
                                                    size='15px'
                                                    type='text'
                                                    name='expiresInDays'
                                                    value={bundle.expiresInDays}
                                                    disabled={bundle.isNonUpdate}
                                                    placeholder='Enter expiry in days'
                                                    handleChange={(e) => setPackageData({ ...packageData, bundle: packageData.bundle.map((item, i) => i === index ? { ...item, expiresInDays: e.target.value } : item) })}
                                                    classname='form-control-2 p-0'
                                                    suffix="days"
                                                />
                                                <label className='text-danger'>{parseInt(bundle.expiresInDays) < 5 ? "Days should be more than 5 days" : parseInt(bundle.expiresInDays) > 360 ? "Days should not be more than 360 days" : ""}</label>
 
 
 
                                            </div>
                                        </div>
                                    </>}
                                    </>
                                ))
                            }
 
                            {packageData.serviceProviderType !== "boarding" && bundleSize === packageData?.bundle?.length ? "" :
                                <>
                                    {/* {packageData?.bundle[packageData?.bundle?.length - 1]?.quantity > 0 ?  */}
                                    {packageData.serviceProviderType !== "boarding" && <div className='col-md-4 pr-0 pl-0'>
                                        <Button
                                            type={'button'}
                                            value='Add Bundle'
                                            className='btn btn-primary btn-block update-profile-btn shadow-none mb-3'
                                            handleClick={() => setPackageData({ ...packageData, bundle: [...packageData.bundle, { quantity: 0, expiresInDays: "" }] })}
                                        />
                                    </div>}
                                    {/* // : ""} */}
                                </>
                            }
                        </form>
 
                        {packageData?._id ? <Button
                            value='Update'
                            className='btn btn-primary btn-block update-profile-btn shadow-none'
                            handleClick={() => { setIsValidate(true); updateData() }}
                        /> :
                            <Button
                                value='Submit'
                                className='btn btn-primary btn-block update-profile-btn shadow-none'
                                handleClick={() => { setIsValidate(true); handleSubmitForm() }}
                            />}
                    </div>
                </Modal.Body >
 
            </Modal >
        </>
    )
}
export default AddPackageModal;