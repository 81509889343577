import React from 'react'

const FormInput = (props) => {
  return (
    <>
      <div className={`form-group input-icons ${props.classname}`}>
        <i className={props.icon} style={{ fontSize: props.size }} />
        <input
          type={props.type}
          name={props.name}
          className='form-control shadow-none'
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.handleChange}
          readOnly={props.readOnly}
          disabled={props.disabled}
        />
      </div>
    </>
  )
}

export default FormInput
