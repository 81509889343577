import React, { Component } from 'react'
import check from '../../../images/check.svg'
import './style.css'

export default class OnboardingSuccess extends Component {
  render () {
    return (
      <>
        <div className='card success_card'>
          <div style={{ margin: '0 auto' }}>
            <img src={check} />
          </div>
          <h1>Onboarding Successful</h1>
          <p>
            close the browser and open the app
          </p>
          <br />
        </div>
      </>
    )
  }
}
