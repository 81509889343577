import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const PackageTable = (props) => {
    const { filterData, packageList, classname, setShowDeleteModal, setShowViewModal, setShowAddPackageModal } = props;
    const [filter, setFilter] = useState("");

    const thTdStyle = {
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '8px',
    };

    const findServiceType = (type) => {
        switch (type) {
            case 'walking':
                return "Walking";
            case 'grooming':
                return "Grooming";
            case 'sitting':
                return "House Sitting";
            case 'boarding':
                return "Boarding";
            case 'visiting':
                return "Drop-in Visits";
            case 'training':
                return "Training";
            default:
                return "Day";
        }
    }
    return (
        <>
            <table className={`table ${classname}`}>
                <thead>
                    <tr>
                        <th style={thTdStyle}>#</th>
                        <th style={thTdStyle}>Title</th>
                        <th style={thTdStyle}>
                            {/* <div className='form-control-2 form-control mb-3 d-flex pr-3 paddingLeft'> */}
                            <select
                                name='selectType'
                                className='shadow-none border-0 w-80 select-status'
                                value={filter}
                                placeholder='Select Service'
                                onChange={(e) => { setFilter(e.target.value); filterData(e.target.value) }}
                                style={{
                                    outline: 'none',
                                    boxShadow: 'none'
                                }}
                            >
                                <option value=''>All</option>
                                <option value='walking'>Walking</option>
                                <option value='boarding'>Boarding</option>
                                <option value='sitting'>House Sitting</option>
                                <option value='visiting'>Drop in visits</option>
                                <option value='grooming'>Grooming</option>
                            </select>
                            {/* </div> */}

                        </th>
                        <th style={thTdStyle}>Duration</th>
                        <th style={thTdStyle}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {packageList &&
                        packageList?.length ?
                        packageList.map((ele, index) => (
                            <>
                                {ele.isDeleted ? "" : <tr >
                                    <td style={thTdStyle}>{index + 1}</td>
                                    <td style={thTdStyle}>{ele.title ? ele.title?.replace(/^./, ele.title[0].toUpperCase()) : ""}</td>
                                    <td style={thTdStyle}>{findServiceType(ele.serviceProviderType)}</td>
                                    <td style={thTdStyle} className='max-w'>{ele.duration} {ele.durationUnit?.replace(/^./, ele.durationUnit[0].toUpperCase())}</td>
                                    <td style={thTdStyle}>
                                        <div onClick={() => setShowViewModal({ show: true, details: ele })} className='btn'>
                                            <i className='fa fa-eye' />
                                        </div>
                                        <div onClick={() => setShowAddPackageModal({ show: true, details: { ...ele, bundle: ele.bundle.map((ele) => ({ ...ele, isNonUpdate: true })) } })} className='btn'>
                                            <i className='fa fa-edit' />
                                        </div>
                                        <Link to='#' className='btn'>
                                            <i
                                                className='fa fa-trash'
                                                onClick={() => setShowDeleteModal({ show: true, id: ele._id })}
                                            />
                                        </Link>
                                    </td>
                                </tr>}
                            </>
                        )) : null}
                </tbody>
            </table>
        </>
    )
}

export default PackageTable;
