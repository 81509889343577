import React from 'react'
import loader from './spinner.gif'

import './styles.scss'

export default () => {
  return (
    <div className='button-loader-container'>
      <img src={loader} alt='Loading...' height='140px' width='140px' />
    </div>
  )
}
