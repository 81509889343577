import React, { Component } from 'react'
import './styles.scss'
import { getCMS, getOwner } from '../../../actions/dashboardActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'

class Terms extends Component {
  constructor (props) {
    super(props)

    this.state = {
      text: '',
      textData: '',
      language: ''
    }
  }

  componentDidMount () {
    const { id } = queryString.parse(this.props.location.search)
    const token = localStorage.getItem('jwtToken')
    this.props.getCMS(token)
    this.props.getOwner(id, token)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps?.dashboard?.singleOwnerInfo) {
      const lang = nextProps?.dashboard?.singleOwnerInfo?.basicInfo?.language
      this.setState({ language: lang })
      if (lang === 'fr') {
        this.setState({ text: nextProps.dashboard.cms.frTerms })
      } else if (lang === 'en') {
        this.setState({ text: nextProps.dashboard.cms.enTerms })
      } else if (lang === 'it') {
        this.setState({ text: nextProps.dashboard.cms.itTerms })
      } else if (lang === 'es') {
        this.setState({ text: nextProps.dashboard.cms.esTerms })
      } else if (lang === 'pl') {
        this.setState({ text: nextProps.dashboard.cms.poTerms })
      } else if (lang === 'de') {
        this.setState({ text: nextProps.dashboard.cms.grTerms })
      } else {
        this.setState({ text: nextProps.dashboard.cms.enTerms })
      }
    }
  }

  handleLanguageChange = (e) => {
    this.setState({ language: e })
  }

  render () {
    const currentLang = this.state.language || 'en'
    const heading = currentLang === 'es'
      ? 'Términos y condiciones'
      : currentLang === 'fr'
        ? 'Termes et conditions'
        : currentLang === 'pl'
          ? 'Zasady i warunki'
          : currentLang === 'it'
            ? 'Termini e Condizioni'
            : currentLang === 'de'
              ? 'Geschäftsbedingungen'
              : 'Terms & conditions'

    return (
      <div className='container-fluid policy'>
        <div className='row mx-0'>
          <div className='col-md-12 px-0'>
            <h4>{heading}</h4>
            <hr />
          </div>
          <div className='col-md-12 px-0'>
            <div dangerouslySetInnerHTML={{ __html: this.state.text }} />
          </div>
        </div>
      </div>
    )
  }
}
Terms.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCMS: PropTypes.func.isRequired,
  getOwner: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, { getCMS, getOwner })(Terms)
