import isEmpty from 'is-empty'

export const validateAddWalker = (
  fullName,
  email,
  phoneNumber
) => {
  if (isEmpty(fullName)) return 'Please enter first name'
  if (fullName.length < 3) return 'First name must be atleast 3 characters'
  if (fullName.length > 255) return 'First name must be atmost 255 characters'

  if (isEmpty(email)) return 'Please enter email'

  if (isEmpty(phoneNumber)) return 'Please enter phone number'
  if (isNaN(phoneNumber)) return 'Please enter a valid phone number'
  if (phoneNumber.length !== 10) return 'Invalid phone number'
}

export const validateEditWalker = (
  fullName,
  email,
  phoneNumber
) => {
  if (isEmpty(fullName)) return 'Please enter full name'
  if (fullName.length < 3) return 'First name must be atleast 3 characters'
  if (fullName.length > 255) return 'First name must be atmost 255 characters'

  if (isEmpty(email)) return 'Please enter email'

  if (isEmpty(phoneNumber)) return 'Please enter phone number'
}
